import { createRouter, createWebHistory } from 'vue-router'
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/Index.vue'),
      meta: { requiresAuth: true },
      children: [{
            path: '/',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/usuario/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'SOLICITUDES', moduloId: 7 },
          },
          {
            path: '/perfil',
            name: 'perfil',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/perfil/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'PERFIL', moduloId: 0 },
          },
          {
            path: '/usuarios',
            name: 'usuarios',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/usuario/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'USUARIOS', moduloId: 7 },
          },
          {
            path: '/trabajadores',
            name: 'Trabajadores',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/trabajadores/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'TRABAJADORES', moduloId: 8 },
          },
          {
            path: '/roles',
            name: 'Roles',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/roles/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'ROLES', moduloId: 1 },
          },
          {
            path: '/unidad_medida',
            name: 'Unidad de medida',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/unidad_medida/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'UNIDAD', moduloId: 2 },
          },
          {
            path: '/lotes',
            name: 'Lotes',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/lote/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'LOTE', moduloId: 3 },
          },
          {
            path: '/actividades',
            name: 'Actividades',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/actividades/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'ACTIVIDAD', moduloId: 4 },
          },
          {
            path: '/productos',
            name: 'Productos',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/producto/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'PRODUCTO', moduloId: 5 },
          },
          {
            path: '/insumos',
            name: 'Insumo',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/insumo/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'INSUMO', moduloId: 6 },
          },
          {
            path: '/bancos',
            name: 'Bancos',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/banco/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'BANCOS', moduloId: 17 },
          },
          {
            path: '/programar',
            name: 'Programar actividades',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/programar/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'PROGRAMAR', moduloId: 9 },
          },
          {
            path: '/actividad/datos/:id',
            name: 'Actividad datos',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/actividadDatos/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'PROGRAMAR', moduloId: 9 },
          },
          {
            path: '/inventario/insumo',
            name: 'Inventario insumo',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/inventario/insumo/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'INVENTARIO-INSUMO', moduloId: 10 },
          },
          {
            path: '/pago/pendiente',
            name: 'Pago-pendiente',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/pagos/pendiente.vue'),
            meta: { requiresAuth: true, moduloName: 'PAGOS-PENDIENTES', moduloId: 11 },
          },
          {
            path: '/pago/pendiente/resumen',
            name: 'Pago-pendiente-resumen',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/pagos/pendienteResumen.vue'),
            meta: { requiresAuth: true, moduloName: 'PAGOS-PENDIENTES', moduloId: 11 },
          },
          {
            path: '/pago/trabajadores',
            name: 'Pago-trabajadores',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/pagos/trabajadores.vue'),
            meta: { requiresAuth: true, moduloName: 'PAGOS-TRABAJADOR', moduloId: 12 },
          },
          {
            path: '/pago/programacion',
            name: 'Pago-programacion',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/pagos/programacion.vue'),
            meta: { requiresAuth: true, moduloName: 'PAGO-PROGRAMACIÓN', moduloId: 13 },
          },
          {
            path: '/pago/programacion/resumen/:identificador',
            name: 'Pago-programacion-resumen',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/pagos/programacionResumen.vue'),
            meta: { requiresAuth: true, moduloName: 'PAGO-PROGRAMACION', moduloId: 13 },
          },
          {
            path: '/pago/historial/fecha',
            name: 'Pagos-historial-fecha',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/pagos/historialFecha.vue'),
            meta: { requiresAuth: true, moduloName: 'PAGO-HISTORIAL', moduloId: 14 },
          },
          {
            path: '/estadisticas/pendientes',
            name: 'Estadistica-Pendiente',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/estadisticas/Index.vue'),
            meta: { requiresAuth: true, moduloName: 'ESTADISTICA-PENDIENTES', moduloId: 18 },
          },
        ]
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "about" */ '../views/auth/Index.vue'),
      meta: { moduloName: 'SIN MODULO' },
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('../views/HomeView.vue'),
      meta: { moduloName: 'SIN MODULO' },
    },
  ]
})
router.beforeEach(async (to, from, next) => {
  let login = (localStorage.getItem('login') == 'true') ? true : false;
  let token = (localStorage.getItem('token')) ? true : false;
  let autenticado = login && token
  if (to.meta?.requiresAuth && !autenticado) {
    next({
      path: '/login',
      replace: true
    })
  } else if (to.name === 'Login' && autenticado) {
    next({
      path: '/',
      replace: true
    })
  } else {
    next()
  }
})

export default router
